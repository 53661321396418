import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/reports/stocks'
export default {
  namespaced: true,
  state: {
    data: [],
    totals: {
      amount: 0,
      entry_amount: 0,
      output_amount: 0,
      criticals: 0,
      entry_priceValue: 0,
      output_priceValue: 0,
    },
    filter: {
      sdate: null,
      edate: null,
      id_warehouses: null,
      id_projects: null,
      invoice_number: null,
    },
    loadingData: false,
  },
  getters: {
    filterParams(state) {
      return state.filter
    },
    reportData(state) {
      return state.data
    },
    totals(state) {
      return state.totals
    },
    loadingData(state) {
      return state.loadingData
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.data = data.data
      state.totals = data.totals
    },
    SET_LOADING_DATA(state, status) {
      state.loadingData = status
    },
  },
  actions: {
    getReport({ commit }, data) {
      commit('SET_LOADING_DATA', true)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
          commit('SET_LOADING_DATA', false)
        })
        .catch(error => console.error(error))
    },
  },
}
