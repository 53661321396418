import Vue from 'vue'
import Vuex from 'vuex'

/* Admin:Core */
import smtpConfig from '@/store/Admin/Core/smtpconfig'
import users from '@/store/Admin/Core/users'
import userTypes from '@/store/Admin/Core/user_types'
import abilities from '@/store/Admin/Core/abilities'
import cropperUploads from '@/store/Admin/Core/cropper_uploads'
import configCompany from '@/store/Admin/Core/config_company'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* Admin:Defines */
import banks from './Admin/Defines/banks'
import bankAccounts from './Admin/Defines/bank_accounts'
import countries from './Admin/Defines/countries'
import cities from './Admin/Defines/cities'
import currencies from './Admin/Defines/currencies'
import customerTypes from './Admin/Defines/customer_types'
import contractTemplates from './Admin/Defines/contract_templates'
import catalogStatuses from './Admin/Defines/catalog_statuses'
import interviewStatuses from './Admin/Defines/interview_statuses'
import interviewSubjects from './Admin/Defines/interview_subjects'
import meets from './Admin/Defines/meets'
import offerStatuses from './Admin/Defines/offer_statuses'
import offerTerms from './Admin/Defines/offer_terms'
import sectors from './Admin/Defines/sectors'
import supplierTypes from './Admin/Defines/supplier_types'
import taxes from './Admin/Defines/taxes'
import units from './Admin/Defines/units'
import activities from './Admin/Defines/activities'
import relatedPersonTypes from './Admin/Defines/related_person_types'
import socialMediaNetworks from './Admin/Defines/socialmedia_networks'
import projectStatuses from './Admin/Defines/project_statuses'
import projectDocumentTypes from './Admin/Defines/project_document_types'
import proformaTerms from './Admin/Defines/proforma_terms'
import stockGroups from './Admin/Defines/stock_groups'
/* Admin:App */
import baskets from './Admin/App/baskets'
import chatMessages from './Admin/App/chat_messages'
import catalogs from './Admin/App/catalogs'
import catalogTracings from './Admin/App/catalog_tracings'
import catalogTracingLines from './Admin/App/catalog_tracing_lines'
import customers from './Admin/App/customers'
import customerAddresses from './Admin/App/customer_addresses'
import customerAccounts from './Admin/App/customer_accounts'
import customerRelatedPersons from './Admin/App/customer_related_persons'
import customerSectors from './Admin/App/customer_sectors'
import customerActivities from './Admin/App/customer_activities'
import customerHistory from './Admin/App/customer_history'
import customerPrices from './Admin/App/customer_prices'
import contracts from './Admin/App/contracts'
import contractDocuments from './Admin/App/contract_documents'
import dashboard from './Admin/App/dashboard'
import offerLines from './Admin/App/offer_lines'
import productGroups from './Admin/Defines/product_groups'
import projectDocuments from './Admin/App/project_documents'
import apiProducts from './Admin/App/api_products'
import offerMailsend from './Admin/App/offer_mailsend'
import products from './Admin/App/products'
import projects from './Admin/App/projects'
import projectLines from './Admin/App/project_lines'
import projectLineItems from './Admin/App/project_line_items'
import projectNotes from './Admin/App/project_notes'
import productItems from './Admin/App/product_items'
import pricing from './Admin/App/pricing'
import proformaInvoices from './Admin/App/proforma_invoices'
import proformaInvoicesMail from './Admin/App/proform_mailsend'
import suppliers from './Admin/App/suppliers'
import supplierRelatedPersons from './Admin/Defines/supplier_related_persons'
import supplierActivities from './Admin/Defines/supplier_activities'
import supplierSectors from './Admin/Defines/supplier_sectors'
import interviews from './Admin/App/interviews'
import interviewFiles from './Admin/App/interview_files'
import reminders from './Admin/App/reminders'
import stocks from './Admin/App/stocks'
import stockLines from './Admin/App/stock_lines'
import offers from './Admin/App/offers'
import warehouses from './Admin/App/warehouses'
/* Admin:Reports */
import customerReports from './Admin/Reports/customerReports'
import interviewReports from './Admin/Reports/interviewReports'
import offerReports from './Admin/Reports/offerReports'
import catalogReports from './Admin/Reports/catalogReports'
import stockReports from './Admin/Reports/stockReports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    smtpConfig,
    users,
    userTypes,
    abilities,
    banks,
    bankAccounts,
    cropperUploads,
    configCompany,
    countries,
    contractTemplates,
    cities,
    currencies,
    customerTypes,
    catalogStatuses,
    interviewStatuses,
    interviewSubjects,
    meets,
    offerStatuses,
    offerTerms,
    offerLines,
    offerMailsend,
    proformaInvoices,
    proformaTerms,
    sectors,
    taxes,
    units,
    activities,
    relatedPersonTypes,
    socialMediaNetworks,
    baskets,
    chatMessages,
    catalogs,
    catalogTracings,
    catalogTracingLines,
    contracts,
    contractDocuments,
    customers,
    customerAddresses,
    customerAccounts,
    customerRelatedPersons,
    customerSectors,
    customerActivities,
    customerHistory,
    customerPrices,
    dashboard,
    productGroups,
    apiProducts,
    products,
    productItems,
    projects,
    projectLines,
    projectLineItems,
    projectStatuses,
    projectDocuments,
    projectDocumentTypes,
    projectNotes,
    proformaInvoicesMail,
    pricing,
    interviews,
    interviewFiles,
    reminders,
    stocks,
    stockGroups,
    stockLines,
    offers,
    customerReports,
    interviewReports,
    offerReports,
    catalogReports,
    stockReports,
    suppliers,
    supplierRelatedPersons,
    supplierTypes,
    supplierActivities,
    supplierSectors,
    warehouses,

  },
  strict: process.env.DEV,
})
